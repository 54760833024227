
import { computed } from "vue";

export default {
	name: "CoreBlockDivider",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const blockContent = computed(() => props.settings.content || null);

		const dividerWidth = computed(() => {
			return props.settings?.dividerWidth || "100%";
		});

		const dividerHorizontalAlignment = computed(() => {
			return props.settings?.dividerHorizontalAlignment || "center";
		});

		return {
			blockContent,
			dividerWidth,
			dividerHorizontalAlignment,
		};
	},
};
